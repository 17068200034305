@layer tailwind-base, primereact, tailwind-utilities;
@layer tailwind-base {
  @tailwind base;
}
@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}
@import "primereact/resources/themes/lara-light-blue/theme.css";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import "react-big-calendar/lib/addons/dragAndDrop/styles";

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #cbe6ff;
}

.Text_Primary,
.Text_Main {
  color: #2870b9;
  font-family: "Lato", sans-serif !important;
}

.Text_Secondary {
  color: #7e8083;
  font-family: "Lato", sans-serif !important;
}

.Text_Disabled {
  color: #b3b9c0;
}

.Text_Error {
  color: #dc2020;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
}

h1 {
  font-size: 96px;
}

h2 {
  font-size: 60px;
}

h3 {
  font-size: 48px;
}

h4 {
  font-size: 34px;
  font-weight: 600;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.Input_Label,
.Helper_Text,
.Input_Text,
.Alert_Title,
.Table_Header {
  font-family: "Lato", sans-serif !important;
  letter-spacing: 0.15px;
}

.Input_Label,
.Table_Header {
  font-size: 15px;
  font-weight: 600;
}

.Helper_Text {
  font-size: 12px;
  font-weight: 400;
}

.Input_Text,
.Alert_Title {
  font-size: 16px;
  font-weight: 400;
}

.Alert_Title {
  font-weight: 500;
}

.Table_Header {
  font-size: 14px;
  font-weight: 500;
}
